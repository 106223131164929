import {Fragment} from "react";

function Header() {
    return (
        <Fragment>
            <section className="header-txt">
                <div>
                    <h1 className="big-headers deep-blue" style={{fontSize: '35px'}}>Bienvenue sur mon portfolio</h1>

                </div>
                <div className="nav-links">
                    <a href="#Work" className="deep-blue"> Mes projets </a>
                    <a href="#Contact" className="deep-blue"> Me contacter </a>
                    {/*<a href="https://www.blog.sami-bahij.com" className="deep-blue" target="_blank"*/}
                    {/*   rel="noopener noreferrer"> Mes articles </a>*/}
                </div>

            </section>
        </Fragment>
    )
}

export default Header;