import * as React from "react";
import { motion } from "framer-motion";
import { AiFillHtml5, AiOutlineConsoleSql } from 'react-icons/ai';
import { IoLogoCss3 } from 'react-icons/io';
import {
    SiAnaconda, SiAndroid, SiCpanel, SiCplusplus, SiCsharp, SiDotnet,
    SiFlutter, SiJavascript, SiJupyter, SiLaravel, SiMysql,
    SiSpring, SiSymfony, SiWordpress
} from 'react-icons/si';
import { BsBootstrap, BsCCircleFill } from 'react-icons/bs';
import { FiGithub } from 'react-icons/fi';
import { GrReactjs } from 'react-icons/gr';
import { DiPhp, DiPython, DiGit, DiEclipse, DiTrello } from 'react-icons/di';
import { FaJava } from "react-icons/fa";

const iconComponents = [
    { Icon: AiFillHtml5, title: "HTML5" },
    { Icon: IoLogoCss3, title: "CSS3" },
    { Icon: BsBootstrap, title: "Bootstrap" },
    { Icon: SiJavascript, title: "JavaScript" },
    { Icon: GrReactjs, title: "React" },
    { Icon: DiPhp, title: "PHP" },
    { Icon: SiLaravel, title: "Laravel" },
    { Icon: SiSymfony, title: "Symfony" },
    { Icon: AiOutlineConsoleSql, title: "SQL" },
    { Icon: SiMysql, title: "MySQL" },
    { Icon: SiWordpress, title: "WordPress" },
    { Icon: BsCCircleFill, title: "C" },
    { Icon: SiCplusplus, title: "C++" },
    { Icon: SiCsharp, title: "C#" },
    { Icon: SiDotnet, title: "dotNet" },
    { Icon: DiPython, title: "Python" },
    { Icon: SiJupyter, title: "Jupyter" },
    { Icon: SiAnaconda, title: "Anaconda" },
    { Icon: FaJava, title: "Java" },
    { Icon: SiSpring, title: "Spring" },
    { Icon: DiEclipse, title: "Eclipse" },
    { Icon: FiGithub, title: "GitHub" },
    { Icon: SiFlutter, title: "Flutter" },
    { Icon: SiAndroid, title: "Android" },
    { Icon: DiGit, title: "Git" },
    { Icon: SiCpanel, title: "cPanel" },
    { Icon: DiTrello, title: "Trello" },
];

const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
        opacity: 1,
        transition: {
            delayChildren: 0.8,
            staggerChildren: 0.1
        }
    }
};

const iconVariants = {
    hidden: { opacity: 0, scale: 0 },
    visible: {
        opacity: 1,
        scale: 1,
        transition: {
            type: "spring",
            stiffness: 260,
            damping: 20,
        }
    }
};

const IconWrapper = ({ Icon, title }) => {
    const [isHovered, setIsHovered] = React.useState(false);

    return (
        <motion.div
            onHoverStart={() => setIsHovered(true)}
            onHoverEnd={() => setIsHovered(false)}
            whileHover={{
                scale: 1.1,
                transition: { duration: 0.3 }
            }}
            whileTap={{ scale: 0.95 }}
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                cursor: 'pointer',
                position: 'relative',
                height: '3rem',
            }}
        >
            <motion.div
                animate={isHovered ? { rotate: [0, 5, -5, 0] } : {}}
                transition={{ duration: 0.3 }}
            >
                <Icon title={title} className="icon" />
            </motion.div>
            <motion.span
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: isHovered ? 1 : 0, y: isHovered ? 0 : 10 }}
                transition={{ duration: 0.2 }}
                style={{
                    fontSize: '0.7rem',
                    marginTop: '0.3rem',
                    position: 'absolute',
                    bottom: '-0.5rem',
                    textAlign: 'center',
                    width: '100%'
                }}
            >
                {title}
            </motion.span>
        </motion.div>
    );
};

export default function Intro() {
    return (
        <motion.div
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            transition={{duration: 0.8}}
        >
            <motion.h1
                className="big-headers"
                initial={{y: -50, opacity: 0}}
                animate={{y: 0, opacity: 1}}
                transition={{duration: 0.5}}
            >
                Bonjour! Je suis Sami
            </motion.h1>
            <motion.p
                className="intro-text"
                initial={{y: 20, opacity: 0}}
                animate={{y: 0, opacity: 1}}
                transition={{duration: 0.5, delay: 0.2}}
            >
                <br/> Actuellement en master MIAGE à l'université de Paris-Nanterre.
            </motion.p>
            <motion.p
                className="intro-text"
                initial={{y: 20, opacity: 0}}
                animate={{y: 0, opacity: 1}}
                transition={{duration: 0.5, delay: 0.4}}
            >
                Je suis un développeur informatique avec un penchant pour le <span className="deep-blue">Back-End.</span>
                <br/><br/>
                Mon parcours académique, en lien direct avec mes aspirations, m’a permis d’acquérir des compétences et des connaissances dans des domaines variés : programmation, bases de données, systèmes informatiques, cybersécurité, économie et marketing digital.
                <br/><br/>
                Curieux et autonome, je m’efforce constamment de progresser, que ce soit à travers la lecture d’ouvrages
                spécialisés ou par la réalisation de projets personnels.
            </motion.p>

            <motion.div
                className="intro-text"
                initial={{opacity: 0, y: 20}}
                animate={{opacity: 1, y: 0}}
                transition={{duration: 0.5, delay: 0.6}}
            >
                <h2 className="text-xl font-semibold mb-4">Engagement Associatif</h2>
                <div style={{overflow: 'hidden'}}>
                    <img
                        src="https://hebbkx1anhila5yf.public.blob.vercel-storage.com/image-Evoe8KPWOtreZhPtIs1pIlE1tpUIN7.png"
                        alt="Logo CLIP"
                        style={{
                            width: '100px',
                            height: 'auto',
                            float: 'left',
                            marginRight: '20px',
                            marginBottom: '10px'
                        }}
                    />
                    <p style={{marginBottom: '10px'}}>
                        Je suis membre de l'association du CLIP (Club Informatique Pénitentiaire), une association à but
                        non lucratif dont la mission est la formation à l'informatique des personnes placées sous main
                        de justice (PPSMJ).
                    </p>
                    <p>
                        J'interviens dans la maison d'arrêt de Nanterre, où je donne des cours sur le développement web
                        avec PHP et des frameworks connus comme Laravel et Symfony. Notre objectif est d'accompagner les
                        personnes détenues dans leur parcours de réinsertion en partageant nos connaissances et
                        expériences en informatique.
                    </p>
                </div>
            </motion.div>
            <motion.div
                className="intro-icons"
                variants={containerVariants}
                initial="hidden"
                animate="visible"
                style={{
                    marginTop: '2rem',
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: '1rem',
                }}
            >
                {iconComponents.map(({Icon, title}) => (
                    <motion.div
                        key={title}
                        variants={iconVariants}
                    >
                        <IconWrapper Icon={Icon} title={title}/>
                    </motion.div>
                ))}
            </motion.div>
        </motion.div>
    );
}